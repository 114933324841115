import React, { useEffect, useState } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

export const WhyUs = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      // Get the updated screen size
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      // Update the state with the new screen size
      setScreenSize(screenWidth);
    }

    // Add event listener for the 'resize' event
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const coTitle = "Why A2Z Computer & Network Solutions ?";
  const coTitleMobile = "Why A2Z CNS ?";

  return (
    <div id="about">
      <main>
        <div className="bg-gray-100 flex justify-center dark:bg-gray-700">
          <div className="md:py-28 py-16 max-w-[1280px] flex flex-col items-center md:gap-20 gap-10">
            <div className="flex flex-col items-center justify-center gap-2 text-center">
              <p className="dark:text-gray-100">WHY US?</p>
              {screenSize < 650 ? (
                <p className="text-4xl font-bold text-blue-800 dark:text-green-400">
                  {coTitleMobile}
                </p>
              ) : (
                <p className=" text-4xl font-bold text-blue-800 dark:text-green-400">
                  {coTitle}
                </p>
              )}
            </div>
            <div className="flex flex-wrap justify-center gap-20 p-6 2xl:p-0">
              <div className="md:w-[45%] flex flex-col gap-6 w-[85%]">
                <div className="flex gap-2 items-center">
                  <IoMdCheckmarkCircleOutline className="text-2xl text-green-600 dark:text-green-400" />

                  <p className="text-3xl dark:text-gray-100">
                    Overseas IT Support
                  </p>
                </div>
                <p className="text-lg text-gray-500 dark:text-gray-300">
                  In the ever-changing landscape of modern business,
                  adaptability is crucial. Our proposition grants you the
                  opportunity to outsource your IT services to our team. Instead
                  of committing to a permanent in-house IT staff member, you can
                  access our expertise on-demand. Essentially, you'll enjoy the
                  benefits of having your dedicated IT department without the
                  associated overhead costs.
                </p>
              </div>
              <div className="md:w-[45%] flex flex-col gap-6 w-[85%]">
                <div className="flex gap-2 items-center">
                  <IoMdCheckmarkCircleOutline className="text-2xl text-green-600 dark:text-green-400" />
                  <p className="text-3xl dark:text-gray-100">Cost-Efficiency</p>
                </div>
                <p className="text-lg text-gray-500 dark:text-gray-300">
                  Recognizing the significance of managing your expenses
                  effectively, at A2Z CNS, you can experience substantial cost
                  reductions. Our cost-effective pricing structures and
                  adaptable service packages ensure that you pay solely for the
                  services you require, precisely when you need them.
                  Transparent and affordable, our business IT solutions come
                  with no concealed fees or unexpected charges - just clear,
                  budget-friendly options.
                </p>
              </div>

              <div className="md:w-[45%] flex flex-col gap-6 w-[85%]">
                <div className="flex gap-2 items-center">
                  <IoMdCheckmarkCircleOutline className="text-2xl text-green-600 dark:text-green-400" />
                  <p className="text-3xl dark:text-gray-100">Top-Tier Skills</p>
                </div>
                <p className="text-lg text-gray-500 dark:text-gray-300">
                  By aligning with A2Z CNS, you unlock a network of IT
                  specialists renowned for their expertise. Our professionals
                  undergo continuous training and take pride in staying abreast
                  of the latest technological breakthroughs. We deliver
                  cutting-edge skills to empower your business to prosper in the
                  digital era.
                </p>
              </div>
              <div className="md:w-[45%] flex flex-col gap-6 w-[85%]">
                <div className="flex gap-2 items-center">
                  <IoMdCheckmarkCircleOutline className="text-2xl text-green-600 dark:text-green-400" />
                  <p className="text-3xl dark:text-gray-100">
                    Local Expertise, Global Reach
                  </p>
                </div>
                <p className="text-lg text-gray-500 dark:text-gray-300">
                  Although rooted in our local community as an IT company, our
                  influence spans across borders. We integrate regional
                  proficiency with an international outlook, offering you a
                  blend of the finest attributes from both realms. Whether you
                  operate as a small local business or a burgeoning enterprise,
                  our services are customized to cater to your distinct
                  requirements.
                </p>
              </div>

              <div className="md:w-[45%] flex flex-col gap-6 w-[85%]">
                <div className="flex gap-2 items-center">
                  <IoMdCheckmarkCircleOutline className="text-2xl text-green-600 dark:text-green-400" />
                  <p className="text-3xl dark:text-gray-100 ">
                    Welcoming, Tailored Assistance
                  </p>
                </div>
                <p className="text-lg text-gray-500 dark:text-gray-300">
                  A2Z CNS operates under the philosophy that technology should
                  not be daunting. Our team, known for its friendly and
                  approachable demeanor, is committed to simplifying the
                  intricacies of technology, delivering personalized service
                  that is not only easy to approach but also highly effective.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
