import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import light_service_help_1 from "../assets/light-service-help-1.png";
import service_help_1 from "../assets/service-help-1.png";
import service_help_2 from "../assets/service-help-2.png";
import service_help_3 from "../assets/service-help-3.png";
import service_help_4 from "../assets/service-help-4.png";
import light_service_help_2 from "../assets/light-service-help-2.png";
import light_service_help_4 from "../assets/light-service-help-4.png";
import light_service_help_3 from "../assets/light-service-help-3.png";
import React from "react";

export const ServicesHelp = ({ serviceDetails, darkLogo }) => {
  // 5 152 2 green
  // 30 64 175 blue
  return (
    <div id="servicesHelp">
      <main>
        <div className="bg-white flex justify-center dark:bg-gray-700">
          <div className=" max-w-[1280px] lg:py-32 flex flex-col lg:gap-10 py-10 xl:px-0 px-16">
            <div className="flex flex-col gap-2 items-center lg:items-start lg:mb-0 mb-10 lg:mt-0 mt-10">
              <p className="text-4xl font-bold text-blue-800 text-center dark:text-green-400">
                How We Can Help
              </p>
            </div>

            <div className="flex flex-col lg:gap-28 gap-6">
              {/* Proactive Network Surveillance: */}
              <div className="flex lg:gap-32 items-center justify-center lg:flex-row flex-col border px-2 rounded-xl lg:border-none lg:rounded-none md:px-0 md:pb-0">
                {!darkLogo ? (
                  <div className="flex">
                    <img
                      src={light_service_help_1}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <img
                      src={service_help_1}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}

                <div className="lg:w-[60%] flex flex-col md:gap-8 gap-4 lg:order-1 text-left lg:mb-0 mb-8 lg:px-0 px-6">
                  <div className="text-3xl text-blue-800 dark:text-green-400">
                    {serviceDetails.bodySubTitleOne}
                  </div>
                  <div className="text-2xl dark:text-gray-200 text-gray-500">
                    {serviceDetails.bodySTSummaryOne}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.onePerksTitleOne}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.onePerksSummaryOne}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.onePerksTitleTwo}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.onePerksSummaryTwo}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.onePerksTitleThree}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.onePerksSummaryThree}
                    </div>
                  </div>
                </div>
              </div>

              {/* In-Depth Management: */}
              <div className="flex lg:gap-32 items-center justify-center lg:flex-row flex-col border px-2 rounded-xl lg:border-none lg:rounded-none md:px-0 md:pb-0">
                <div className="lg:w-[60%] flex flex-col justify-center md:gap-8 gap-4 text-left lg:order-none order-1 lg:mb-0 mb-8 lg:px-0 px-6">
                  <div className="text-3xl text-blue-800 dark:text-green-400">
                    {serviceDetails.bodySubTitleTwo}
                  </div>
                  <div className="text-2xl dark:text-gray-200 text-gray-500">
                    {serviceDetails.bodySTSummaryTwo}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.twoPerksTitleOne}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.twoPerksSummaryOne}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.twoPerksTitleTwo}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.twoPerksSummaryTwo}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.twoPerksTitleThree}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.twoPerksSummaryThree}
                    </div>
                  </div>
                </div>
                {!darkLogo ? (
                  <div className="flex">
                    <img
                      src={light_service_help_2}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <img
                      src={service_help_2}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}
              </div>

              {/* Economical Solutions and Efficiency: */}
              <div className="flex lg:gap-32 items-center justify-center lg:flex-row flex-col border px-2  rounded-xl lg:border-none lg:rounded-none lg:px-0 lg:pb-0">
                {!darkLogo ? (
                  <div className="flex">
                    <img
                      src={light_service_help_3}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <img
                      src={service_help_3}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}

                <div className="lg:w-[60%] flex flex-col justify-center lg:gap-8 gap-4 lg:order-1text-left lg:mb-0 mb-8 lg:px-0 px-6">
                  <div className="text-3xl text-blue-800 dark:text-green-400">
                    {serviceDetails.bodySubTitleThree}
                  </div>
                  <div className="text-2xl dark:text-gray-200 text-gray-500">
                    {serviceDetails.bodySTSummaryThree}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.threePerksTitleOne}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.threePerksSummaryOne}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.threePerksTitleTwo}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.threePerksSummaryTwo}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.threePerksTitleThree}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.threePerksSummaryThree}
                    </div>
                  </div>
                </div>
              </div>

              {/*Tailor-Made Solutions: */}
              <div className="flex lg:gap-32 items-center justify-center lg:flex-row flex-col border px-2 rounded-xl lg:border-none lg:rounded-none lg:px-0 lg:pb-0">
                <div className="lg:w-[60%] flex flex-col justify-center md:gap-8 gap-4 text-left order-1 lg:order-none lg:mb-0 mb-8 lg:px-0 px-6">
                  <div className="text-3xl text-blue-800 dark:text-green-400">
                    {serviceDetails.bodySubTitleFour}
                  </div>
                  <div className="text-2xl dark:text-gray-200 text-gray-500">
                    {serviceDetails.bodySTSummaryFour}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-500 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.fourPerksTitleOne}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.fourPerksSummaryOne}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-400 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.fourPerksTitleTwo}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.fourPerksSummaryTwo}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4 text-xl font-bold">
                      <IoMdCheckmarkCircleOutline className="text-2xl text-green-400 dark:text-green-400" />
                      <p className="dark:text-gray-100">
                        {serviceDetails.fourPerksTitleThree}
                      </p>
                    </div>
                    <div className="text-lg text-gray-500 pl-10 pr-40 dark:text-gray-300">
                      {serviceDetails.fourPerksSummaryThree}
                    </div>
                  </div>
                </div>
                {!darkLogo ? (
                  <div className="flex">
                    <img
                      src={light_service_help_4}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <img
                      src={service_help_4}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
