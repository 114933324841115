// import A2ZCNSLogo from "../assets/a2z-cns-logo.png";
import Logo1 from "../assets/vvv.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const Header = ({ darkLogo, setDarkLogo }) => {
  const [homeActive, setHomeActive] = useState(true);
  const [servicesActive, setServicesActive] = useState(false);
  const [whyUsActive, setwhyUsActive] = useState(false);
  const [contactUsActive, setContactUsActive] = useState(false);
  const [hidden, setHidden] = useState(true);

  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  );

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));

    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const [isHome, setIsHome] = useState(true);

  const route = useLocation();

  const inView = (view) => {
    const viewRect = view?.getBoundingClientRect();
    // console.log(viewRect.y, window.innerHeight / 2);
    if (
      viewRect &&
      viewRect.y < window.innerHeight / 2 &&
      viewRect.y > -window.innerHeight
    ) {
      return true;
    }
    return false;
  };

  const setAllInactive = () => {
    setHomeActive(false);
    setServicesActive(false);
    setwhyUsActive(false);
    setContactUsActive(false);
  };

  useEffect(() => {
    if (route.pathname !== "/") {
      setIsHome(false);
      window.onscroll = null;
    } else {
      setIsHome(true);
      window.addEventListener("scroll", () => {
        const homeView = document.querySelector("#home");
        const servicesView = document.querySelector("#services");
        const whyUsView = document.querySelector("#about");
        const contactUsView = document.querySelector("#contacts");
        // console.log(inView(homeView));
        if (inView(homeView)) {
          setAllInactive();
          setHomeActive(true);
        } else if (inView(servicesView)) {
          setAllInactive();
          setServicesActive(true);
        } else if (inView(whyUsView)) {
          setAllInactive();
          setwhyUsActive(true);
        } else if (inView(contactUsView)) {
          setAllInactive();
          setContactUsActive(true);
        }
      });
    }
  }, [route]);

  return (
    <header>
      <nav
        className="bg-white border-gray-200 dark:bg-gray-800 border-b-2 dark:border-b-1 dark:border-gray-800 h-20 w-full bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-95
"
      >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
          <a href={isHome ? "#home" : "/"}>
            <img src={Logo1} className="w-[50px] mt-1.5" alt="A2Z-CNS" />
          </a>

          <div id="mobile-nav" className="flex md:order-2">
            <button
              onClick={() => {
                setDarkMode(!darkMode);
                setDarkLogo(!darkLogo);
              }}
              data-tooltip-target="navbar-search-example-toggle-dark-mode-tooltip"
              type="button"
              data-toggle-dark="light"
              className="flex items-center p-2 mr-2 text-xs font-medium text-gray-700 bg-white rounded-lg border border-gray-200 toggle-dark-state-example hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              {darkMode ? (
                <svg
                  aria-hidden="true"
                  data-toggle-icon="sun"
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  aria-hidden="true"
                  data-toggle-icon="moon"
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                </svg>
              )}
            </button>

            <button
              data-collapse-toggle="navbar-search"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-search"
              aria-expanded="false"
              onClick={() => setHidden(!hidden)}
            >
              <span className="sr-only">Open Menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`${
              hidden ? "hidden" : ""
            } items-center justify-between w-full md:flex md:w-auto md:order-1`}
            id="navbar-search"
          >
            <ul className="flex flex-col p-2 mt-4 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 dark:bg-gray-800 md:dark:bg-gray-800 dark:border-gray-700 bg-gray-300 md:bg-transparent">
              <li>
                <a
                  href={isHome ? "#home" : "/"}
                  className={
                    homeActive
                      ? "transition-all duration-1000 ease-in-out text-lg py-2 pl-3 pr-4 text-white bg-blue-800  md:bg-transparent md:text-blue-700 md:p-2 md:dark:text-green-400 md:border-b-[3px] block md:inline border-blue-700 md:dark:border-green-500"
                      : "text-lg py-2 pl-3 pr-4 text-gray-900  hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-2 md:dark:hover:text-green-400 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent md:border-b-[3px] md:border-transparent dark:border-transparent block md:inline"
                  }
                >
                  HOME
                </a>
              </li>

              <li>
                <a
                  href="#services"
                  className={
                    servicesActive
                      ? "transition-all duration-1000 ease-in-out text-lg py-2 pl-3 pr-4 text-white bg-blue-800  md:bg-transparent md:text-blue-700 md:p-2 md:dark:text-green-400 md:border-b-[3px] block md:inline border-blue-700 md:dark:border-green-500"
                      : "text-lg py-2 pl-3 pr-4 text-gray-900  hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-2 md:dark:hover:text-green-400 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent md:border-b-[3px] md:border-transparent dark:border-transparent block md:inline"
                  }
                >
                  SERVICES
                </a>
              </li>
              <li>
                <a
                  href={isHome ? "#about" : "/"}
                  className={
                    whyUsActive
                      ? "transition-all duration-1000 ease-in-out text-lg py-2 pl-3 pr-4 text-white bg-blue-800  md:bg-transparent md:text-blue-700 md:p-2 md:dark:text-green-400 md:border-b-[3px] block md:inline border-blue-700 md:dark:border-green-500"
                      : "text-lg py-2 pl-3 pr-4 text-gray-900  hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-2 md:dark:hover:text-green-400 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent md:border-b-[3px] md:border-transparent dark:border-transparent block md:inline"
                  }
                >
                  WHY US
                </a>
              </li>

              <li>
                <a
                  href={isHome ? "#contacts" : "/"}
                  className={
                    contactUsActive
                      ? "transition-all duration-1000 text-lg py-2 pl-3 pr-4 text-white bg-blue-800  md:bg-transparent md:text-blue-700 md:p-2 md:dark:text-green-400 md:border-b-[3px] block md:inline border-blue-700 md:dark:border-green-500"
                      : "text-lg py-2 pl-3 pr-4 text-gray-900  hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-2 md:dark:hover:text-green-400 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent md:border-b-[3px] md:border-transparent dark:border-transparent block md:inline"
                  }
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
