import { Home } from "./Home";
import { Contact } from "./Contact";
import { WhyUs } from "./WhyUs";

import { ServicesHero } from "./ServicesHero";

export const MainBody = ({ darkLogo }) => {
  return (
    <div className="dark:bg-slate-700">
      <Home darkLogo={darkLogo} />
      <ServicesHero darkLogo={darkLogo} />
      <WhyUs />
      <Contact darkLogo={darkLogo} />
    </div>
  );
};
