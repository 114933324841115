import React from "react";
import {  Routes, Route } from "react-router-dom";
import { MainBody } from "../pages/MainBody";
import { Services } from "../pages/Services";

export default function AllRoutes({ darkLogo, setDarkLogo }) {
  return (
    <Routes>
      <Route element={<MainBody darkLogo={darkLogo} />} path="/" />
      <Route element={<Services darkLogo={darkLogo} />} path="/services" />
    </Routes>
  );
}
