import React, { useEffect } from "react";
import { ServicesIntro } from "./ServicesIntro.jsx";
import { ServicesHelp } from "./ServicesHelp.jsx";
import data from "../data/index.js";
import { useLocation } from "react-router-dom";

export const Services = ({ darkLogo }) => {
  const userId = useLocation().search;
  const urlParams = new URLSearchParams(userId);
  const serviceCategory = urlParams.get("id");

  const serviceDetails = data[serviceCategory];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <ServicesIntro serviceDetails={serviceDetails} darkLogo={darkLogo} />
      <ServicesHelp serviceDetails={serviceDetails} darkLogo={darkLogo} />
    </main>
  );
};
