import { NavLink } from "react-router-dom";
import managed_services from "../assets/managed-services.png";
import light_managed_services from "../assets/light-managed-service.png";
import end_user_support from "../assets/end-user-support.png";
import light_end_user_support from "../assets/light-end-user-support.png";
import project_management from "../assets/project-management.png";
import light_project_management from "../assets/light-project-management.png";
import installation_provisioning from "../assets/installation-provisioning.png";
import light_installation_provisioning from "../assets/light-installation-provisioning.png";
import it_staffing from "../assets/it-staffing.png";
import light_it_staffing from "../assets/light-it-staffing.png";
import React from "react";

export const ServicesHero = ({ darkLogo }) => {
  return (
    <div id="services" className="">
      <main>
        <div className="bg-white flex justify-center dark:bg-gray-700">
          <div className="md:py-28 py-16 max-w-[1280px] flex flex-col items-center md:items-start p-10  gap-8 md:gap-0">
            <div className="flex flex-col gap-2 items-center md:items-start">
              <p className="dark:text-gray-200">SERVICES</p>
              <p className="text-4xl font-bold text-blue-800 text-center dark:text-green-400">
                What we can do for you
              </p>
            </div>

            <div className="flex flex-col md:gap-0 gap-6">
              <div className="flex md:gap-10 items-center justify-center md:flex-row flex-col border px-2 pb-4 rounded-xl md:border-none md:rounded-none md:px-0 md:pb-0">
                {!darkLogo ? (
                  <div className="flex">
                    <img
                      src={light_managed_services}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <img
                      src={managed_services}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}

                <div className="md:w-[80%] flex flex-col justify-center md:gap-8 gap-4 md:order-1 text-center md:text-left">
                  <p className="text-3xl text-blue-800 dark:text-green-400">
                    Managed Services
                  </p>
                  <p className="text-xl dark:text-gray-200 text-gray-900">
                    We attend to the requirements of your IT framework,
                    overseeing and handling monitoring tasks to guarantee
                    seamless operational flow.
                  </p>
                  <div className="py-4 transform transition-transform hover:scale-110 md:w-[200px] duration-500">
                    <NavLink
                      to={"/services?id=managedServices"}
                      className="px-6 py-4 bg-blue-800 w-32 text-center rounded-xl text-gray-100 dark:bg-green-600"
                    >
                      Show More
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="flex md:gap-10 items-center justify-center md:flex-row flex-col border px-2 pb-4 rounded-xl md:border-none md:rounded-none md:px-0 md:pb-0">
                <div className="w-[80%] flex flex-col justify-center md:gap-8 gap-4 text-center md:text-right order-1 md:order-none">
                  <p className="text-3xl text-blue-800 dark:text-green-400">
                    End User Support
                  </p>
                  <p className="text-xl dark:text-gray-200 text-gray-600">
                    Our approachable and extensively proficient technicians
                    stand prepared to deliver swift assistance either on-site or
                    remotely, ensuring your team swiftly resumes work with
                    minimal disruptions.
                  </p>

                  <div className=" transform transition-transform hover:scale-110 duration-500 flex justify-center md:ml-auto">
                    <NavLink
                      to={"/services?id=endUserSupport"}
                      className="py-4 bg-blue-800 w-32 text-center rounded-xl text-gray-100 dark:bg-green-600"
                    >
                      Show More
                    </NavLink>
                  </div>
                </div>
                {!darkLogo ? (
                  <div>
                    <img
                      src={light_end_user_support}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      src={end_user_support}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}
              </div>

              <div className="flex md:gap-10 items-center justify-center md:flex-row flex-col border px-2 pb-4 rounded-xl md:border-none md:rounded-none md:px-0 md:pb-0">
                {!darkLogo ? (
                  <div className="flex">
                    <img
                      src={light_it_staffing}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <img
                      src={it_staffing}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}
                <div className="md:w-[80%] flex flex-col justify-center md:gap-8 gap-4 md:order-1 text-center md:text-left">
                  <p className="text-3xl text-blue-800 dark:text-green-400">
                    IT Staffing
                  </p>
                  <p className="text-xl dark:text-gray-200 text-gray-600">
                    Select from a thoughtfully assembled cohort of seasoned IT
                    experts to become part of your workforce, providing
                    assistance for both short-term and extended durations..
                  </p>
                  <div className="py-4 transform transition-transform hover:scale-110 md:w-[200px] duration-500">
                    <NavLink
                      to={"/services?id=itStaffing"}
                      className="px-6 py-4 bg-blue-800 w-32 text-center rounded-xl text-gray-100 dark:bg-green-600"
                    >
                      Show More
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="flex md:gap-10 items-center justify-center md:flex-row flex-col border px-2 pb-4 rounded-xl md:border-none md:rounded-none md:px-0 md:pb-0">
                <div className="w-[80%] flex flex-col justify-center md:gap-8 gap-4 text-center md:text-right order-1 md:order-none">
                  <p className="text-3xl text-blue-800 dark:text-green-400">
                    Project Management
                  </p>
                  <p className="text-xl dark:text-gray-200 text-gray-600">
                    Our panel of specialists personally manages every facet of
                    your IT initiatives, guaranteeing the prompt and proficient
                    execution of the provided solutions.
                  </p>

                  <div className=" transform transition-transform hover:scale-110 duration-500 flex justify-center md:ml-auto">
                    <NavLink
                      to={"/services?id=projectManagement"}
                      className="py-4 bg-blue-800 w-32 text-center rounded-xl text-gray-100 dark:bg-green-600"
                    >
                      Show More
                    </NavLink>
                  </div>
                </div>
                {!darkLogo ? (
                  <div>
                    <img
                      src={light_project_management}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      src={project_management}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}
              </div>

              <div className="flex md:gap-10 items-center justify-center md:flex-row flex-col border px-2 pb-4 rounded-xl md:border-none md:rounded-none md:px-0 md:pb-0">
                {!darkLogo ? (
                  <div className="flex">
                    <img
                      src={light_installation_provisioning}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <img
                      src={installation_provisioning}
                      alt="software-development"
                      className="w-[500px]"
                    />
                  </div>
                )}

                <div className="md:w-[80%] flex flex-col justify-center md:gap-8 gap-4 md:order-1 text-center md:text-left">
                  <p className="text-3xl text-blue-800 dark:text-green-400">
                    Installation and Provisioning
                  </p>
                  <p className="text-xl dark:text-gray-200 text-gray-600">
                    Leveraging our expertise in the setup and configuration of
                    new IT equipment, our solutions reduce downtime to a minimum
                    and optimize the efficiency of any team.
                  </p>
                  <div className="py-4 transform transition-transform hover:scale-110 md:w-[200px] duration-500">
                    <NavLink
                      to={"/services?id=installationProvisioning"}
                      className="px-6 py-4 bg-blue-800 w-32 text-center rounded-xl text-gray-100 dark:bg-green-600"
                    >
                      Show More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
