const data = {
  managedServices: {
    bodyTitle: "Managed Services",
    summary:
      "A2Z CNS's Managed IT Services aim to deliver extensive support and oversight for your IT infrastructure. Focused on proactive monitoring and effective management, we guarantee your company encounters smooth and continuous operations. The following are key components of our Managed Services:",

    image: "../assets/home-banner-1.png",

    bodySubTitleOne: "Proactive Network Surveillance:",
    bodySTSummaryOne:
      "Our Managed Services kick off with thorough surveillance of your IT infrastructure. Here's how it contributes to the well-being of your organization:",
    onePerksTitleOne: "Live Monitoring:",
    onePerksSummaryOne:
      "We employ state-of-the-art tools to actively monitor your systems, allowing us to detect potential issues before they interrupt your operations.",
    onePerksTitleTwo: "Forecasting Analysis:",
    onePerksSummaryTwo:
      "Utilizing past data and advanced predictive analytics, we predict and address potential future issues, lessening downtime and avoiding costly emergencies.",
    onePerksTitleThree: "Immediate Issue Resolution:",
    onePerksSummaryThree:
      "Once issues are recognized, our team of experts swiftly implements resolution procedures to ensure minimal disruption to your business workflows.",

    bodySubTitleTwo: "In-Depth Management:",
    bodySTSummaryTwo:
      "Our Managed Services go beyond monitoring to include thorough administration of your IT landscape:",
    twoPerksTitleOne: "Patch Control:",
    twoPerksSummaryOne:
      "We ensure your systems are current with the most recent patches and updates, boosting security and efficiency.",
    twoPerksTitleTwo: "Resource Oversight:",
    twoPerksSummaryTwo:
      "We manage a record of your IT resources, guaranteeing effective allocation and enabling prompt upgrades to hardware and software.",
    twoPerksTitleThree: "Safety Oversight:",
    twoPerksSummaryThree:
      "Safeguarding your data and network is our top concern. Our Managed Services incorporate robust security measures, including firewall oversight, intrusion detection, and regular security evaluations.",

    bodySubTitleThree: "Economical Solutions and Efficiency:",
    bodySTSummaryThree:
      "By taking advantage of managed services, you can reap a range of financial rewards:",
    threePerksTitleOne: "Minimal Disruption:",
    threePerksSummaryOne:
      "Our proactive approach to monitoring and resolving issues ensures that your team's productivity remains high and your income stream is uninterrupted, minimizing downtime.",
    threePerksTitleTwo: "Adaptable Solutions:",
    threePerksSummaryTwo:
      "We offer services that can be tailored to your organization's evolving requirements, allowing you to maximize resource allocation and minimize wasteful spending.",
    threePerksTitleThree: "Cost Transparency:",
    threePerksSummaryThree:
      "With fixed monthly fees, you can accurately plan your IT budget and eliminate the uncertainty associated with on-demand IT support.",

    bodySubTitleFour: "Tailor-Made Solutions:",
    bodySTSummaryFour:
      "Unlock the ultimate efficiency and success with solutions designed specifically for your organization.",
    fourPerksTitleOne: "Enhanced Efficiency:",
    fourPerksSummaryOne:
      "Eliminate wasted resources and maximize productivity with solutions optimized for your specific needs.",
    fourPerksTitleTwo: "Improved Flexibility:",
    fourPerksSummaryTwo:
      "Adapt and evolve with ease as your business changes with solutions designed to be flexible and scalable.",
    fourPerksTitleThree: "Unwavering Support:",
    fourPerksSummaryThree:
      "Enjoy the peace of mind that comes with dedicated support from our team of experts, ensuring you're always on track for success.",
  },
  endUserSupport: {
    bodyTitle: "End User Support",
    summary:
      "At A2Z CNS, our unwavering commitment to exceptional IT services also encompasses our End User Support. We understand that technical glitches can disrupt your workflow and productivity, which is why our friendly and highly skilled technicians are dedicated to promptly assisting you, whether through on-site help or remote support",

    bodySubTitleOne: "On-Site Help",
    bodySTSummaryOne:
      "Our on-site support services are customized to address technical issues that require physical intervention. Here's what you can expect:",
    onePerksTitleOne: "Skilled Professionals:",
    onePerksSummaryOne:
      "Our team consists of extensively trained experts well-equipped to identify and resolve various technical challenges.",
    onePerksTitleTwo: "Skilled Professionals:",
    onePerksSummaryTwo:
      "Our team consists of extensively trained experts well-equipped to identify and resolve various technical challenges.",
    onePerksTitleThree: "Assistance for Hardware and Software:",
    onePerksSummaryThree:
      "Whether contending with a hardware malfunction or a software glitch, we've got you covered. We'll work diligently to swiftly restore your systems to smooth operation.",

    bodySubTitleTwo: "Remote Assistance:",
    bodySTSummaryTwo:
      "For issues that can be addressed remotely, our remote support service provides a convenient and effective solution:",
    twoPerksTitleOne: "Cost-Effective:",
    twoPerksSummaryOne:
      "Remote support not only ensures prompt issue resolution but also offers an economical alternative to traditional on-site technical support.",
    twoPerksTitleTwo: "Swift Problem Solving:",
    twoPerksSummaryTwo:
      "Our technicians can remotely access your systems to diagnose and troubleshoot issues, delivering speedy solutions without the need for an on-site visit.",
    twoPerksTitleThree: "Secure Connectivity:",
    twoPerksSummaryThree:
      "Rest assured that our remote support sessions occur over secure and encrypted connections, guaranteeing the safety of your data.",

    bodySubTitleThree: "Reducing Operational Delays:",
    bodySTSummaryThree:
      "We recognize the potential cost and disruption associated with downtime. Our End User Support is designed to minimize such inefficiencies through various means:",
    threePerksTitleOne: "Proactive Surveillance:",
    threePerksSummaryOne:
      "We actively monitor your systems to detect potential issues before they lead to disruptions. This proactive approach enables us to take preventive actions and maintain the smooth operation of your business.",
    threePerksTitleTwo: "24/7 Accessibility:",
    threePerksSummaryTwo:
      "Technical issues can arise at any time, not just during regular working hours. Our support services are accessible 24/7 to ensure you have assistance whenever needed.",
    threePerksTitleThree: "Efficient Issue Resolution:",
    threePerksSummaryThree:
      "Our technicians excel in streamlined troubleshooting, ensuring accurate diagnosis and prompt resolution of issues.",

    bodySubTitleFour: "Customer-Focused Approach:",
    bodySTSummaryFour:
      "Our approach to End User Support revolves around meeting your specific requirements:",
    fourPerksTitleOne: "Customized Solutions:",
    fourPerksSummaryOne:
      "Recognizing the unique nature of each organization, our support services are tailored to align with your distinct IT infrastructure and operational needs.",
    fourPerksTitleTwo: "Approachable and Amiable:",
    fourPerksSummaryTwo:
      "Our technicians not only bring expertise to the table but also maintain a friendly and approachable demeanor. Delivering exceptional customer service is our priority.",
    fourPerksTitleThree: "Training and Assistance:",
    fourPerksSummaryThree:
      "Beyond issue resolution, we offer guidance and training to empower your team, fostering self-sufficiency in addressing minor technical issues.",
  },
  itStaffing: {
    bodyTitle: "IT Staffing",
    summary:
      "At A2Z CNS, we offer a unique IT staffing solution designed to empower your business by providing specialized professionals tailored to your specific needs. Dive into the details of our IT Staffing services and explore how we can enhance your team with experienced IT talent:",
    bodySubTitleOne: "Skill Set and Expertise",
    bodySTSummaryOne:
      "Enhance your projects with a wealth of expertise customized to your needs through our team of IT professionals. Each expert brings top-tier skills to elevate your team.",
    onePerksTitleOne: "Diverse Skill Sets",
    onePerksSummaryOne:
      "Select from a varied pool of professionals, each equipped with the exact skills your projects require.",
    onePerksTitleTwo: "Expert Guidance",
    onePerksSummaryTwo:
      "Benefit from their insights, best practices, and industry knowledge as they contribute to the success of your projects.",
    onePerksTitleThree: "Reduced Errors and Risks",
    onePerksSummaryThree:
      "Minimize errors and project risks with the guidance and expertise of skilled IT professionals.",
    bodySubTitleTwo: "Support Options",
    bodySTSummaryTwo:
      "Explore adaptable support options designed to align with your business dynamics and effortlessly scale your IT support.",
    twoPerksTitleOne: "Temporary and Long-Term Support",
    twoPerksSummaryOne:
      "We offer flexible options, encompassing both temporary and long-term support by our IT professionals are prepared to support your endeavors.",
    twoPerksTitleTwo: "Scalability and Flexibility",
    twoPerksSummaryTwo:
      "Our solution provides scalability and flexibility, enabling you to easily adjust the size and composition of your IT team based on changing demands.",
    twoPerksTitleThree: "Tailored Onboarding",
    twoPerksSummaryThree:
      "We customize the onboarding program to meet the specific needs of your organization and project requirements.",
    bodySubTitleThree: "Efficiency and Convenience",
    bodySTSummaryThree:
      "Efficiently streamline your hiring process for immediate support without the hassle through our efficient IT staffing solution, paying for talent as you need it.",
    threePerksTitleOne: "Streamlined Recruitment Process",
    threePerksSummaryOne:
      "We've curated a group of experienced IT professionals ready to hit the ground running. Say goodbye to the lengthy hiring process and hello to immediate support.",
    threePerksTitleTwo: "Cost-Effective Solution",
    threePerksSummaryTwo:
      " With our IT Staffing services, you pay for the talent you need when you need it, avoiding the overhead of permanent hires.",
    threePerksTitleThree: "Guaranteed Performance",
    threePerksSummaryThree:
      "We stand behind the quality of our services and offer performance guarantees to ensure your satisfaction with our IT professionals.",
    bodySubTitleFour: "Responsiveness and Customization",
    bodySTSummaryFour:
      "With A2Z CNS, experience rapid responses to IT challenges, minimizing downtime and losses.",
    fourPerksTitleOne: "Rapid Response",
    fourPerksSummaryOne:
      "IT emergencies can occur at any time. When urgent issues arise, our professionals are available to address them promptly.",
    fourPerksTitleTwo: "Customized to Your Needs",
    fourPerksSummaryTwo:
      "We collaborate closely with you to understand your specific needs and tailor our IT staffing solution accordingly. ",
    fourPerksTitleThree: "Regular System Monitoring",
    fourPerksSummaryThree:
      "Our IT professionals continuously monitor your systems for potential problems, identifying and addressing them before they impact your operations.",
  },
  projectManagement: {
    bodyTitle: "Project Management",
    summary:
      "At A2Z, we take pride in our dedication to delivering IT projects with precision and efficiency. Our dedicated team of specialists is available to guide you through each stage of your project, from start to finish. Examine the following major components of our extended administrations:",
    bodySubTitleOne: "Comprehensive Oversight:",
    bodySTSummaryOne:
      "A2Z CNS can help you achieve a higher degree of project management. Our skilled team directly handles all elements of your IT projects, ensuring a thorough approach that leaves no detail overlooked.",
    onePerksTitleOne: "Holistic Supervision:",
    onePerksSummaryOne:
      "Dedicated project managers oversee all aspects, ensuring meticulous attention to detail.",
    onePerksTitleTwo: "Resource Optimization: ",
    onePerksSummaryTwo:
      "Experts allocate the right talents and tools, maximizing efficiency and cost-effectiveness.",
    onePerksTitleThree: "Quality Assurance:",
    onePerksSummaryThree:
      "Rigorous checks guarantee high-quality results exceeding industry standards.",
    bodySubTitleTwo: "Transparent Communication:",
    bodySTSummaryTwo:
      "Our open communication procedures will keep you engaged and informed throughout your project's lifecycle.",
    twoPerksTitleOne: "Open Channels:",
    twoPerksSummaryOne:
      "We maintain clear communication channels for regular project updates Progress Milestones: Stay informed with regular milestone updates tracking progress and key deliverables.",
    twoPerksTitleTwo: "Progress Milestones:",
    twoPerksSummaryTwo:
      "We deliver regular milestones updates so that you remain apprised of your project's status, can watch its development, and anticipate crucial deliverables.",
    twoPerksTitleThree: "Responsive Support:",
    twoPerksSummaryThree:
      "Our team promptly answers your questions and addresses concerns, providing peace of mind.",
    bodySubTitleThree: "Post-Project Assurance:",
    bodySTSummaryThree:
      "Our dedication goes beyond the conclusion of the project. Discover the benefits of our post-project assistance.",
    threePerksTitleOne: "Ongoing Assistance:",
    threePerksSummaryOne:
      "We offer continuous support and maintenance after project completion. Performance Monitoring: Regular checks ensure your solutions' peak performance, with swift issue resolution.",
    threePerksTitleTwo: "Performance Monitoring:",
    threePerksSummaryTwo:
      "We perform regular checks to ensure your solutions continue to perform at their best, by monitoring your systems' performance to identify and resolve any issues swiftly.",
    threePerksTitleThree: "Solutions Optimization: ",
    threePerksSummaryThree:
      "Experts provide recommendations for improvements and enhancements based on evolving needs.",
    bodySubTitleFour: "Swift & Well-Executed Solutions:",
    bodySTSummaryFour:
      "Involvement the A2Z contrast with extend arrangements that are not as it were quick but too well-executed, with a center on proficiency and proactiveness.",
    fourPerksTitleOne: "Efficiency Focus:",
    fourPerksSummaryOne:
      "We deliver IT projects with precision and efficiency, ensuring timely completion within budget.",
    fourPerksTitleTwo: "Customized to Your Needs",
    fourPerksSummaryTwo:
      "We tailor our solutions to your specific needs, working closely with you to choose professionals who align with your project goals and company culture.",
    fourPerksTitleThree: "Proactive Management: ",
    fourPerksSummaryThree:
      "We identify and mitigate potential risks proactively, keeping your project on track and ensuring success.",
  },
  installationProvisioning: {
    bodyTitle: "Installation and Provisioning",
    summary:
      "Whether you're establishing a new office, expanding your current infrastructure, or upgrading outdated equipment, A2Z's professionals are here to assist you. Our installation services cover a broad spectrum of IT equipment, including servers, workstations, networking hardware, and more. Explore further details below:",
    bodySubTitleOne: "Efficient Deployment",
    bodySTSummaryOne:
      "Reduce maintenance delay and boost efficiency by deploying equipment effectively.",
    onePerksTitleOne: "Swift Setup:",
    onePerksSummaryOne:
      "Our expert specialists ensure that IT equipment is installed and configured quickly, decreasing downtime and increasing the efficiency of your team.",
    onePerksTitleTwo: "Customized Configuration:",
    onePerksSummaryTwo:
      "Customized configuration for each device guarantees that new equipment is effortlessly incorporated into your existing IT ecosystem.",
    onePerksTitleThree: "Deployment with minimal disruption:",
    onePerksSummaryThree:
      "Our rigorous preparation and execution ensure that your workflow is not disrupted. You may be confident that your business will continue to run properly during installation. ",
    bodySubTitleTwo: "Integration of Advanced Hardware and Software",
    bodySTSummaryTwo:
      "If you're looking for hardware or software integration, our experts are well-trained to handle any scenario.",
    twoPerksTitleOne: "Hardware Proficiency:",
    twoPerksSummaryOne:
      "Our skilled professionals are experts at configuring a wide range of IT systems, from workstations and servers to network devices and accessories. ",
    twoPerksTitleTwo: "Comprehensive Software Integration:",
    twoPerksSummaryTwo:
      "We additionally incorporate the application supplied with the hardware, ensuring complete operation and compatibility.",
    twoPerksTitleThree: "Quality Assurance Testing:",
    twoPerksSummaryThree:
      "Thorough testing following installation guarantees the optimal performance of your IT equipment, free from any issues.",
    bodySubTitleThree: "Scalable Solutions to Facilitate Future Expansion",
    bodySTSummaryThree:
      "Anticipate and support future growth through installation and provisioning solutions that can adapt to your evolving requirements.",
    threePerksTitleOne: "Scalability:",
    threePerksSummaryOne:
      "Our services are crafted to facilitate the expansion of your business, providing a seamless process for integrating new equipment as the need arises.",
    threePerksTitleTwo: "Adaptability:",
    threePerksSummaryTwo:
      "Whether you need a singular workstation configuration or a complete network setup, our solutions are versatile and customized to your specific scale.",
    threePerksTitleThree: "Future-Ready Investments:",
    threePerksSummaryThree:
      "We consider lasting tech trends in equipment provisioning to ensure your investments stay relevant in the ever-evolving IT landscape.",
    bodySubTitleFour: "Expert Guidance and Ongoing Support",
    bodySTSummaryFour:
      "Gain advantages from skilled advice, training, and uninterrupted support for your IT equipment.",
    fourPerksTitleOne: "Consultative Expertise:",
    fourPerksSummaryOne:
      "Benefit from our team's consultative expertise, aiding in selecting the right equipment and setup strategies for your business goals.",
    fourPerksTitleTwo: "Training Support:",
    fourPerksSummaryTwo:
      "We provide training and onboarding assistance, ensuring efficient utilization of newly installed equipment by your staff.",
    fourPerksTitleThree: "Continuous Maintenance:",
    fourPerksSummaryThree:
      "Our commitment goes beyond installation, offering sustained support and maintenance services to promptly address any arising issues, ensuring a productive IT infrastructure.",
  },
};

export default data;
