import { useEffect, useState } from "react";
import { Header, Footer } from "./components";
import AllRoutes from "./routes/AllRoutes";

function App() {
  const [darkLogo, setDarkLogo] = useState(
    JSON.parse(localStorage.getItem("darkLogo")) || false
  );

  useEffect(() => {
    localStorage.setItem("darkLogo", JSON.stringify(darkLogo));
  }, [darkLogo]);

  return (
    <div className="App">
      <div className="sticky top-0 z-50">
        <Header setDarkLogo={setDarkLogo} darkLogo={darkLogo}/>
      </div>
      <AllRoutes setDarkLogo={setDarkLogo} darkLogo={darkLogo}/>
      <Footer />
    </div>
  );
}

export default App;
