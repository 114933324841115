import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BiLogoLinkedin } from "react-icons/bi";
import { PiCopyright } from "react-icons/pi";

export const Footer = () => {
  return (
    <div className="bg-white py-10 dark:bg-gray-800 flex justify-center border-t-2 dark:border-transparent">
      <div className=" dark:bg-gray-800 dark:text-white flex md:gap-20 gap-10 bg-white md:justify-evenly md:flex-col flex-col md:items-center px-6">
        <div className="flex md:gap-56 md:flex-row flex-col gap-16">
          <div className="flex flex-col gap-8 justify-center">
            <div>
              <Link to="/" className="flex items-center">
                <p className="text-3xl font-bold dark:text-green-400 text-blue-800 flex flex-col gap-2">
                  <p className="text-blue-800 dark:text-green-500">
                    A2Z Solutions
                  </p>
                  <p className="text-green-500 dark:text-blue-700">
                    A2Z Computer & Network Solutions
                  </p>
                </p>
              </Link>
            </div>
            <div>
              <p className="text-xl font-semibold dark:text-gray-200">
                Need Any Help?
              </p>
              <p className="font-bold dark:text-gray-200">
                Call Us:{" "}
                <span className="text-yellow-600">(+880) 1911 989234</span>
              </p>
              <p className="font-bold dark:text-gray-200 ml-[59px]">
                <span className="text-yellow-600">(+880) 1611 222766</span>
              </p>
            </div>
            <div className="dark:text-gray-200">
              <div className="flex flex-col gap-4">
                <div>
                  <p className="font-bold">Corporate Office : </p>
                  <p>
                    Khan Villa, House # Ka - 60, Joar Shahara,, Dhaka - 1229,
                    Bangladesh
                  </p>
                </div>
                <div>
                  <p className="font-bold">Mohakhali Office : </p>
                  <p>Ga - 34/1, Mohakhali, Dhaka - 1212, Bangladesh</p>
                </div>
                <div>
                  <p>alam@a2zcns.com</p>
                  <p>ralambd@gmail.com</p>
                </div>
              </div>
            </div>
            <div className="flex gap-4 text-xl dark:text-gray-100">
              <FaFacebookSquare className="cursor-pointer" />
              <BsTwitter className="cursor-pointer" />
              <BiLogoLinkedin className="cursor-pointer" />
            </div>
          </div>
          <div className="flex lg:gap-20 gap-6 items-center ">
            <div className="flex flex-col gap-6">
              <p className="text-3xl font-semibold dark:text-gray-100">
                Company
              </p>
              <p className="dark:text-gray-300">About Us</p>
              <p className="dark:text-gray-300">Contact Us</p>
              <p className="dark:text-gray-300">Our Projects</p>
              <p className="dark:text-gray-300">Data Policy</p>
            </div>
            <div className="flex flex-col gap-6">
              <p className=" text-3xl font-semibold dark:text-gray-100">
                Our Services
              </p>
              <p className="dark:text-gray-300">Managed Services</p>
              <p className="dark:text-gray-300">End User Supports</p>
              <p className="dark:text-gray-300">Project Management</p>
              <p className="dark:text-gray-300">
                Installation and Provisioning
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center gap-2 dark:text-gray-200">
          <PiCopyright />
          <p>
            2023{" "}
            <span className="dark:text-green-400 text-blue-800">A2Z-CNS</span>{" "}
            All Right Reserved
          </p>
        </div>
      </div>
    </div>
  );
};
