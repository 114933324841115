import React, { useState, useEffect } from "react";
import home_banner_1 from "../assets/home-banner-1.png";
import light_home_banner_1 from "../assets/light-home-banner-1.png";
import APLLogistics from "../assets/customersAssets/APL Logistics Bangladesh.png";
import EmbassyOfIndonesia from "../assets/customersAssets/EMBASSY OF THE REPUBLIC OF INDONESIA Dhaka Bangladesh.png";
import EmbassyOfPhilippines from "../assets/customersAssets/Embassy of The Republic of The Philippines Dhaka.png";
import EuropeanUnion from "../assets/customersAssets/European Union in Bangladesh.png";
import HealthCarePharmaceutical from "../assets/customersAssets/Healthcare Pharmaceuticals Limited Bangladesh.png";
import HighCommissionMalaysia from "../assets/customersAssets/High Commission Of Malaysia Dhaka.png";
import HighCommissionBruneiDarussalam from "../assets/customersAssets/High Commission of Brunei Darussalam Dhaka.png";
import PaperFlyBangladesh from "../assets/customersAssets/Paperfly Bangladesh.png";

export const Home = ({ darkLogo }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      // Get the updated screen size
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      // Update the state with the new screen size
      setScreenSize(screenWidth);
    }

    // Add event listener for the 'resize' event
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="home">
      <main>
        {/* INTRODUCTION */}
        <div className="bg-gray-100 flex justify-center dark:bg-gray-700">
          <div className=" max-w-[1280px] flex lg:py-24 py-6 lg:gap-12 gap-4 lg:flex-row flex-col items-center lg:text-left text-center p-10">
            <div className="flex flex-col lg:gap-10 gap-6  lg:w-[70%] w-[90%] items-center lg:items-start">
              <div className="flex lg:gap-6 gap-4 font-bold flex-col">
                {screenSize < 860 ? (
                  <div className="flex flex-col gap-2">
                    <p className="text-blue-800 text-4xl dark:text-green-400">
                      A2Z Solutions
                    </p>
                    <p className="text-green-500 text-4xl dark:text-blue-500">
                      A2Z Computer & Network Solutions
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col gap-2">
                    <p className="text-blue-800 text-4xl dark:text-green-500">
                      A2Z Solutions
                    </p>
                    <p className="text-green-500 text-4xl dark:text-blue-500">
                      A2Z Computer & Network Solutions
                    </p>
                  </div>
                )}
                <p className="lg:text-3xl text-2xl dark:text-gray-200">
                  Your trusted Development Partner
                </p>
              </div>
              <p className="lg:text-2xl text-xl dark:text-gray-400">
                A2Z CNS, a pioneer in microcomputers in Bangladesh, is committed
                to providing accessible information technology solutions to
                businesses, government, and educational institutions. The
                company focuses on innovative tools that streamline expression,
                analysis, and information management, crafted with extensive
                product knowledge and a customer-centric approach. A leader in
                introducing various technologies, A2Z CNS offers comprehensive
                solutions, including the rental and sale of computers and office
                equipment. Their flexible lease/rental services cater to
                short-term, midterm, or long-term agreements with monthly
                installment options.
              </p>

              <a
                href="#contacts"
                className="relative h-12 w-52 overflow-hidden border border-blue-700 text-blue-700 shadow-2xl transition-all duration-400 before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:m-auto before:h-0 before:w-0 before:rounded-sm before:bg-blue-700 before:duration-300 before:ease-out hover:text-white hover:shadow-blue-700 hover:before:h-40 hover:before:w-52 hover:before:opacity-80 rounded-xl flex justify-center items-center dark:text-white dark:border-green-600 before:dark:bg-green-500 dark:hover:text-white dark:hover:shadow-green-500"
              >
                <span className="relative z-10">Tell Us What You Want</span>
              </a>
            </div>
            {!darkLogo ? (
              <div className="p-[5%] lg:p-0">
                <img
                  src={light_home_banner_1}
                  alt="home_banner"
                  className="w-[750px]"
                />
              </div>
            ) : (
              <div className="p-[5%] lg:p-0">
                <img
                  src={home_banner_1}
                  alt="home_banner"
                  className="w-[750px]"
                />
              </div>
            )}
          </div>
        </div>

        {/* SATISFIED CUSTOMERS */}
        <div className="bg-gray-100 flex justify-center dark:bg-gray-700">
          <div className=" max-w-[1280px] lg:py-32 flex flex-col items-center gap-20 py-10">
            <div className="flex flex-col gap-3">
              <p className="md:text-4xl text-3xl font-bold dark:text-green-400 text-center text-blue-800">
                SATISFIED CUSTOMERS
              </p>
              <p className="text-xl font-bold dark:text-gray-300 text-center text-gray-600 px-6">
                Our customers always comes first at A2Z, and we are proud to
                work with some of the world's best known brands.
              </p>
            </div>

            <div className="flex md:gap-20 gap-10 flex-wrap justify-center px-10 items-center">
              <div className="flex flex-col justify-center items-center gap-4 dark:text-gray-300 w-[200px]">
                <img
                  src={APLLogistics}
                  alt="APL Logistics, Bangladesh"
                  className="md:w-[150px] w-[100px]"
                />
                <p>APL Logistics Bangladesh</p>
              </div>
              <div className="flex flex-col justify-center items-center gap-4 dark:text-gray-300 w-[200px]">
                <img
                  src={EmbassyOfIndonesia}
                  alt="Embassy of The Republic of Indonesia, Dhaka, Bangladesh"
                  className="md:w-[150px] w-[100px]"
                />
                <p className="text-center">
                  Embassy of The Republic of Indonesia
                </p>
              </div>
              <div className="flex flex-col justify-center items-center gap-4 dark:text-gray-300 w-[200px]">
                <img
                  src={EmbassyOfPhilippines}
                  alt="Embassy of The Republic of Philippines, Dhaka, Bangladesh"
                  className="md:w-[150px] w-[100px]"
                />
                <p className="text-center">
                  Embassy of The Republic of Philippines
                </p>
              </div>
              <div className="flex flex-col justify-center items-center gap-4 dark:text-gray-300 w-[200px]">
                <img
                  src={EuropeanUnion}
                  alt="European Union, Bangladesh"
                  className="md:w-[150px] w-[100px]"
                />
                <p className="text-center">European Union</p>
              </div>
              <div className="flex flex-col justify-center items-center gap-4 dark:text-gray-300 w-[200px]">
                <img
                  src={HealthCarePharmaceutical}
                  alt="Healthcare Pharmaceuticals Limited, Bangladesh"
                  className="md:w-[150px] w-[100px]"
                />
                <p className="text-center">
                  Healthcare Pharmaceuticals Limited
                </p>
              </div>
              <div className="flex flex-col justify-center items-center gap-4 dark:text-gray-300 w-[200px]">
                <img
                  src={HighCommissionMalaysia}
                  alt="High Commission of Malaysia, Dhaka, Bangladesh"
                  className="md:w-[150px] w-[100px]"
                />
                <p className="text-center">High Commission of Malaysia</p>
              </div>
              <div className="flex flex-col justify-center items-center gap-4 dark:text-gray-300 w-[200px]">
                <img
                  src={HighCommissionBruneiDarussalam}
                  alt="High Commission of Brunei Darussalam, Dhaka, Bangladesh"
                  className="md:w-[150px] w-[100px]"
                />
                <p className="text-center">
                  High Commission of Brunei Darussalam
                </p>
              </div>
              <div className="flex flex-col justify-center items-center gap-4 dark:text-gray-300 w-[200px]">
                <img
                  src={PaperFlyBangladesh}
                  alt="Paper Fly Bangladesh"
                  className="md:w-[150px] w-[100px]"
                />
                <p className="text-center">Paper Fly</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
