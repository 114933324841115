import { MdArrowForwardIos } from "react-icons/md";
import light_service_intro from "../assets/light-service-intro.png";
import service_intro from "../assets/service-intro.png";

export const ServicesIntro = ({ serviceDetails, darkLogo }) => {
  return (
    <div id="servicesIntro">
      <main>
        {/* INTRODUCTION */}
        <div className="bg-gray-100 flex justify-center dark:bg-gray-700">
          <div className=" max-w-[1280px] flex lg:py-24 py-6 lg:gap-12 lg:flex-row flex-col items-center lg:text-left text-center p-10">
            <div className="flex flex-col  dark:text-gray-200 lg:w-[70%] w-[90%] items-center lg:items-start">
              <div className="flex text-center gap-2 font-bold mb-3">
                <p className="text-gray-600 dark:text-gray-200">Home</p>
                <div className="flex items-center gap-2 text-blue-800">
                  <MdArrowForwardIos className="dark:text-green-400" />

                  <p className="dark:text-green-400">Services</p>
                </div>
              </div>
              <div className="flex lg:gap-6 gap-4 font-bold flex-col mb-6">
                <p className=" text-blue-800 text-4xl dark:text-green-400">
                  {serviceDetails.bodyTitle}
                </p>
              </div>
              <p className="lg:text-xl text-lg mb-10 dark:text-gray-300 text-gray-600">
                {serviceDetails.summary}
              </p>

              <a
                href="#servicesHelp"
                className="before:ease relative h-12 w-40 overflow-hidden  bg-blue-700 text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-700 hover:shadow-blue-700 hover:before:-translate-x-52 rounded-lg flex items-center justify-center dark:bg-green-500 dark:hover:shadow-green-500"
              >
                <span relative="relative z-10">Learn How</span>
              </a>
            </div>
            {!darkLogo ? (
              <div className=" w-[70%] lg:w-[100%]">
                <img
                  src={light_service_intro}
                  alt="home_banner"
                  className="w-[750px]"
                />
              </div>
            ) : (
              <div className=" w-[70%] lg:w-[100%]">
                <img
                  src={service_intro}
                  alt="home_banner"
                  className="w-[750px]"
                />
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
